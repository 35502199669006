<template>

<div class="contentMainDiv">
<div class="columns is-flex is-vcentered">
   <div class="column is-3">

        <figure class="image is-1by2">
  <img src="@/assets/gypaete.jpg">
</figure>
  </div>

  <div class="column ">
   <div class="columns">
     <div class="column is-1"></div><div class="column">
     <p class="title">
        Notre Mission 
              </p><br/>
      <p class="subtitle">
        Conseil en stratégie digitale 
    <br/>
        Infrastructures Broadcast et IT
    <br/>
       Gestion de projets et accompagnement au changement
      </p></div></div>
  </div>
 
</div>
</div>
  

</template>

<script>
export default {
  name: 'header-component',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ul {
  list-style-type: none;
  padding: 0;
}
h1 {
 
  color: #F6F2D4;
  font-size: 30px;
  font-weight: bold;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #F6F2D4;
}

.title {
color:#F6F2D4;
font-size:60px;
}
.subtitle {
color:#F6F2D4;
font-size:20px;
}


.contentMainDiv {
background-color:#22577E;
height: 100%;

}



</style>
