<template>
  <div class="contentMainDiv">
    <div class="is-hidden-touch">
      <br /><br />
      <br /><br />
    </div>
    <div class="is-hidden-desktop"><center>
      <p class="titlemobile"><br/>
    Nous contacter
      </p>
      <br />
      <p class="subtitle">
                
              Nous sommes à votre disposition pour échanger sur vos projets et imaginer avec vous l'accompagnement dont vous avez besoin.

      </p>
      <div class="columns is-mobile is-vcentered">
        <div class="column is-3" style="background-color:#fffff"> <figure class="image is-64x64">
              <img src="@/assets/email.png" />
            </figure>
        </div>
        <div class="column"><h4>
    contact@alpinit.fr</h4>
        </div>
      </div>
            <div class="columns is-mobile is-vcentered">    <div class="column is-3" style="background-color:#fffff"> <figure class="image is-64x64">
              <img src="@/assets/phone.png" />
            </figure>
        </div>
                      <div class="column"> <h4>    + 33 7 84 55 19 43</h4>
        </div>
    

      </div>
            <div class="columns is-mobile is-vcentered">
        <div class="column is-3" style="background-color:#fffff"> <figure class="image is-64x64">
              <img src="@/assets/map.png" />
            </figure>
        </div>
        <div class="column">
         <h4>Paris - Annecy - Genève</h4>
        </div>
      </div></center>
    </div>
    <div class="columns  is-desktop is-hidden-touch is-vcentered">
      <div class="column is-7">
        <div class="columns">
          <div class="column is-1"></div>
        
          <div class="column">
            <figure class="image is-128x128">
              <img src="@/assets/email.png" />
            </figure>
          </div>
          <div class="column is-7">
        <h3><br/>contact@alpinit.fr</h3><br /><br />
            <br /><br />
          </div>
          <div class="column is-1"></div>
          <div class="column is-1"></div>
        </div>
        <div class="columns ">
          <div class="column">
            <div class="columns">
              <div class="column is-1"></div>
              <div class="column is-7"> <h3> <br/> + 33 7 84 55 19 43 </h3>
                <br /><br />
                <br /><br />
              </div>
              <div class="column">
                <figure class="image is-128x128">
                  <img src="@/assets/phone.png" />
                </figure>
              </div>
            </div>
            <div class="columns">
              <div class="column is-1"></div>
              <div class="column">
                <figure class="image is-128x128">
                  <img src="@/assets/map.png" />
                </figure>
              </div>
              <div class="column is-7"><br/><h3>
              Paris - Annecy - Genève</h3> <br /><br />
                <br /><br />
              </div>
              <div class="column is-1"></div>
              <div class="column is-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <p class="title">
          Nous <br />
          Contacter
        </p>
        <br />
        <p class="subtitle">
                    
              Nous sommes à votre disposition pour échanger sur vos projets et imaginer avec vous l'accompagnement dont vous avez besoin.

        </p>
      </div>
      <div class="column is-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-component",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
h1 {
  color: #f6f2d4;
  font-size: 30px;
  font-weight: bold;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #f6f2d4;
}

.title {
  color: #f6f2d4;
  font-size: 60px;
}
.titlemobile {
  color: #f6f2d4;
  font-size: 40px;
  text-align: center;
}
.subtitle {
  color: #ffffff;
  font-size: 20px;
}

.column {
  color: #f6f2d4;
  font-size: 20px;
}

h4 {
  color: #f6f2d4;
  font-size: 15px;
}

h3 {
  color: #f6f2d4;
  font-size: 30px;
}

.contentMainDiv {
  background-color: #22577e;
  height: 100%;
}
</style>
