<template>
  <div class="header-component">
  <b-navbar class="header-bar">
        <template #brand>
            <b-navbar-item href="#" v-on:click="sendNewIDtoMain(0)">
                <img
                    src="montagnes.png"
                > 
            <h1>&nbsp; AlpinIT</h1>
            </b-navbar-item>
        </template>

        <template #end>
        
            <b-navbar-item href="#" v-on:click="sendNewIDtoMain(0)">
                Accueil
            </b-navbar-item>
 <!--           <b-navbar-item href="#" v-on:click="sendNewIDtoMain(1)">
                Notre Mission
            </b-navbar-item>-->
            <b-navbar-item href="#" v-on:click="sendNewIDtoMain(2)">
                Nos Références
            </b-navbar-item>
            <b-navbar-item href="#" v-on:click="sendNewIDtoMain(3)">
                Notre Méthode
            </b-navbar-item>
            <b-navbar-item href="#" v-on:click="sendNewIDtoMain(4)">
                Nous contacter
            </b-navbar-item>
        </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'header-component',
  props: {
    msg: String
  },
  methods:{
      
      sendNewIDtoMain(newValue){
        this.$emit("menuID", newValue);
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ul {
  list-style-type: none;
  padding: 0;
}
h1 {
 
  color: #F6F2D4;
  font-size: 30px;
  font-weight: bold;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #F6F2D4;
}

.header-component {

/* Set a background color that will be displayed
while the background image is loading */
background-color: #22577E;



}
.hero.is-primary{
  color: #F6F2D4;



}


</style>
