<template>
  <div class="contentMainDiv">
    <div class="is-hidden-touch">
      <br /><br />
      <br /><br />
    </div>
    <div class="is-hidden-desktop"><center>
      <p class="titlemobile"><br/>
        Notre méthode
      </p>
      <br />
      <p class="subtitle">
        Une approche responsable et constructive pour mener à bien tous vos
        projets stratégiques
      </p>
      <div class="columns is-mobile is-vcentered">
        <div class="column is-3" style="background-color:#fffff"> <figure class="image is-64x64">
              <img src="@/assets/project.png" />
            </figure>
        </div>
        <div class="column"><h4>
          Une expertise en gestion de projets et en accompagnement au changement</h4>
        </div>
      </div>
            <div class="columns is-mobile is-vcentered">
                      <div class="column"> <h4>Une méthode centrée sur l'évaluation des besoins, des rôles et
                responsabilités et un reporting régulier et sur mesure</h4>
        </div>
        <div class="column is-3" style="background-color:#fffff"> <figure class="image is-64x64">
              <img src="@/assets/checklist.png" />
            </figure>
        </div>

      </div>
            <div class="columns is-mobile is-vcentered">
        <div class="column is-3" style="background-color:#fffff"> <figure class="image is-64x64">
              <img src="@/assets/certificate.png" />
            </figure>
        </div>
        <div class="column">
         <h4>
                Des certifications reconnues par l'industrie et des formations
                régulières pour assurer la qualité technique de nos conseils
                (AWS Solution Architect, Microsoft Azure)</h4>
        </div>
      </div></center>
    </div>
    <div class="columns  is-desktop is-hidden-touch is-vcentered">
      <div class="column is-7">
        <div class="columns ">
          <div class="column is-1"></div>
        
          <div class="column">
            <figure class="image is-128x128">
              <img src="@/assets/project.png" />
            </figure>
          </div>
          <div class="column is-7"><br/>
            Une expertise en gestion de projets et en accompagnement au
            changement <br /><br />
            <br /><br />
          </div>
          <div class="column is-1"></div>
          <div class="column is-1"></div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="columns ">
              <div class="column is-1"></div>
              <div class="column is-7"><br/>
                Une méthode centrée sur l'évaluation des besoins, des rôles et
                responsabilités et un reporting régulier et sur mesure
                <br /><br />
                <br /><br />
              </div>
              <div class="column">
                <figure class="image is-128x128">
                  <img src="@/assets/checklist.png" />
                </figure>
              </div>
            </div>
            <div class="columns">
              <div class="column is-1"></div>
              <div class="column">
                <figure class="image is-128x128">
                  <img src="@/assets/certificate.png" />
                </figure>
              </div>
              <div class="column is-7">
                Des certifications reconnues par l'industrie et des formations
                régulières pour assurer la qualité technique de nos conseils
                (AWS Solution Architect, Microsoft Azure) <br /><br />
                <br /><br />
              </div>
              <div class="column is-1"></div>
              <div class="column is-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <p class="title">
          Notre <br />
          Méthode
        </p>
        <br />
        <p class="subtitle">
          Une approche responsable et constructive pour mener à bien tous vos
          projets stratégiques
        </p>
      </div>
      <div class="column is-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-component",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
h1 {
  color: #f6f2d4;
  font-size: 30px;
  font-weight: bold;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #f6f2d4;
}

.title {
  color: #f6f2d4;
  font-size: 60px;
}
.titlemobile {
  color: #f6f2d4;
  font-size: 40px;
  text-align: center;
}
.subtitle {
  color: #ffffff;
  font-size: 20px;
}

.column {
  color: #f6f2d4;
  font-size: 20px;
}

h4 {
  color: #f6f2d4;
  font-size: 15px;
}

.contentMainDiv {
  background-color: #22577e;
  height: 100%;
}
</style>
