<template>
  <div class="contentMainDiv">
    <div class="is-hidden-touch">
      <br /><br />
      <br /><br />
    </div>
    <div class="is-hidden-desktop"><center>
      <p class="titlemobile"><br/>
        Nos références
      </p>
      <br />
      <p class="subtitle">
                    10 ans d'expérience dans l'accompagnement d'événements sportifs de renommée
              mondiale au service de l'industrie du Broadcast et de l'IT.
      </p>
      <div class="columns is-mobile is-vcentered">
        <div class="column is-3" style="background-color:#fffff"> <figure class="image is-64x64">
              <img src="@/assets/stade.png" />
            </figure>
        </div>
        <div class="column"><h4>
    
Responsable technique et chef de projet pour les tournois de Roland-Garros et du Rolex Paris Masters</h4>
        </div>
      </div>
            <div class="columns is-mobile is-vcentered">
                      <div class="column"> <h4>   Responsable du Network Operations Center (NOC) lors d'événements sportifs majeurs  (transmissions par satellite, fibre optique et streaming)
            </h4>
        </div>
        <div class="column is-3" style="background-color:#fffff"> <figure class="image is-64x64">
              <img src="@/assets/dish.png" />
            </figure>
        </div>

      </div>
            <div class="columns is-mobile is-vcentered">
        <div class="column is-3" style="background-color:#fffff"> <figure class="image is-64x64">
              <img src="@/assets/code.png" />
            </figure>
        </div>
        <div class="column">
         <h4>
             En charge du développement et de la gestion de projet d'applications sur mesure (gestion de données sportives en temps réel, applications web, plateformes OTT, Media Asset Management)</h4>
        </div>
      </div></center>
    </div>
    <div class="columns  is-desktop is-hidden-touch is-vcentered">
      <div class="column is-7">
        <div class="columns">
          <div class="column is-1"></div>
        
          <div class="column">
            <figure class="image is-128x128">
              <img src="@/assets/stade.png" />
            </figure>
          </div>
          <div class="column is-7"><br/>
Responsable technique et chef de projet pour les tournois de Roland-Garros et du Rolex Paris Masters  <br /><br />
            <br /><br />
          </div>
          <div class="column is-1"></div>
          <div class="column is-1"></div>
        </div>
        <div class="columns is-vcentered">
          <div class="column">
            <div class="columns">
              <div class="column is-1"></div>
              <div class="column is-7">  <br/>Responsable du Network Operations Center (NOC) lors d'événements sportifs majeurs  (transmissions par satellite, fibre optique et streaming)
                <br /><br />
                <br /><br />
              </div>
              <div class="column">
                <figure class="image is-128x128">
                  <img src="@/assets/dish.png" />
                </figure>
              </div>
            </div>
            <div class="columns">
              <div class="column is-1"></div>
              <div class="column">
                <figure class="image is-128x128">
                  <img src="@/assets/code.png" />
                </figure>
              </div>
              <div class="column is-7"><br/>
             En charge du développement et de la gestion de projet d'applications sur mesure (gestion de données sportives en temps réel, applications web, plateformes OTT, Media Asset Management)<br /><br />
                <br /><br />
              </div>
              <div class="column is-1"></div>
              <div class="column is-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <p class="title">
          Nos <br />
          Références
        </p>
        <br />
        <p class="subtitle">
                     
                    10 ans d'expérience dans l'accompagnement d'événements sportifs de renommée
              mondiale au service de l'industrie du Broadcast et de l'IT.
        </p>
      </div>
      <div class="column is-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-component",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
h1 {
  color: #f6f2d4;
  font-size: 30px;
  font-weight: bold;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #f6f2d4;
}

.title {
  color: #f6f2d4;
  font-size: 60px;
}
.titlemobile {
  color: #f6f2d4;
  font-size: 40px;
  text-align: center;
}
.subtitle {
  color: #ffffff;
  font-size: 20px;
}

.column {
  color: #f6f2d4;
  font-size: 20px;
}

h4 {
  color: #f6f2d4;
  font-size: 15px;
}

.contentMainDiv {
  background-color: #22577e;
  height: 100%;
}
</style>
