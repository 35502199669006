<template>
  <div id="app" class="appclass">
    <HeaderComp @menuID="changeMenuValue"/>
    <Content v-if="currentSection==0" />
    <Mission v-if="currentSection==1" />
    <References v-if="currentSection==2" />
    <Methode v-if="currentSection==3" />
    <Contact v-if="currentSection==4" />
    <footer class="footer">
  <div class="content has-text-centered is-fixed-bottom">
    <p>
     Alpinit - SASU au capital de 1000€ - Siège Social 507 CHEMIN DE PIERRE PLATE 74170 SAINT GERVAIS LES BAINS - 
908 743 628 R.C.S. Annecy - Tous droits réservés - 2024<br/>
      Toutes les images sont la propriété d'Alpinit SASU à l'exception des icônes conçues par <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/fr/" title="Flaticon">www.flaticon.com</a>
    </p>
  </div>
</footer>


  </div>
</template>

<script>
import HeaderComp from './components/Header.vue'


import Content from './components/Home.vue'

import Mission from './components/Mission.vue'
import References from './components/References.vue'

import Methode from './components/Methode.vue'

import Contact from './components/Contact.vue'

export default {
  name: 'App',
  components: {
    HeaderComp,
    Content,
    Methode,
    References,
    Mission,
    Contact
  },
        data() {
            return {
                currentSection:0,
            }
        },
        methods: {
changeMenuValue(newvalue)
{
  this.currentSection = newvalue;
  console.log(newvalue);
}
        }


}
</script>

<style lang="scss">
@import "@/sass/mystyles.scss";

footer{
    padding: 2rem 1rem 1rem !important;
}
a {
  color: #F6F2D4;
}
.footer {
background-color:#22577E;
color:white;
font-size:8px;
}

html, body {
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}
 html { background-color: #22577E; }
</style>
