<template>
  <div class="contentMainDiv">
    <div class="is-hidden-touch">
      <br /><br />
      <br /><br />
    </div>

    <div class="columns is-vcentered">
      
      <div class="column is-6">
        <h3><center>
          <p class="title">
            <br />
            AlpinIT
          </p><br/>
          <p class="subtitle">
            Conseil en stratégie digitale
            <br />
            <br />
            Architecture des infrastructures Broadcast et IT
            <br />
            <br />
            Gestion de projet et accompagnement au changement
            <br />
            <br />
            Optimisation des opérations
          </p></center>
        </h3>
      </div>
      <div class="column is-hidden-touch">
        <b-image :src="require('../assets/banner.jpg')" ratio="10by7"></b-image>
      </div>
    </div>
    <div class="column is-hidden-desktop">
      <b-image :src="require('../assets/banner.jpg')" ratio="10by7"></b-image>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-component",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
h1 {
  color: #f6f2d4;
  font-size: 30px;
  font-weight: bold;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #f6f2d4;
}

.title {
  color: #f6f2d4;
  font-size: 60px;
}
.subtitle {
  color: #f6f2d4;
  font-size: 20px;
}
.subtitlemobile {
  color: #f6f2d4;
  font-size: 20px;
  text-align: center;
}

.titlemobile {
  color: #f6f2d4;
  font-size: 40px;
  text-align: center;
}

.contentMainDiv {
  background-color: #22577e;
  height: 100%;
}
</style>
